.module-selector{
  border : 2px solid @grey;
  height: 120px;
  display: block;
  text-align: center;
  margin: 15px 0;
  color: @dark-grey;
  background: #fff;
  cursor: grab;

  img{
    line-height: 120px;
    display: block;
    margin: 20px auto 0 auto;
    vertical-align: middle;
    max-width: 60%;
  }


  hr{
    margin: 30px 10px;
    border-top: 5px solid @dark-grey;
  }

  p{
    text-align: center;
    color: @dark-grey;
    position: absolute;
    bottom: 0;
    left: 3px;
    right: 3px;
    margin-bottom: 3px;
  }

}

.module--container{
  border: 1px solid @grey;
  margin: 15px 0;
  position: relative;
  //height: 250px;
  textarea{
    width: 100%;
    height: 200px;
    text-align: left;
    padding: 10px;
    // border: none;
    resize: none;
    
    &.small {
      height: 100px;
    }
  }


  .module-radio{
    textarea.form-control{
      border: 1px solid @grey;
      height: 150px;
    }
  }


  .module--footer{
    background: fade(@grey,60%);
    padding: 0 15px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 0;
    transition: height 0.3s ease;
    overflow: hidden;
    z-index: 20;
    .btn{
      padding: 5px 20px;
      height: 30px;
      margin: 10px 10px 0 0;
      text-transform: uppercase;

      @media(max-width: @screen-desktop){
        margin: 10px 5px 0 0;
        padding: 5px 9px;

        &.btn-primary{
          min-width: 120px;
        }
      }

    }


    .btn-group{
      padding-top: 10px;
    }
  }


  .message{
    height: 25px;
    line-height: 25px;
    padding: 0 15px;
    display: none;
    position: absolute;
    bottom: 0;
    z-index: 999;
    font-weight: 800;
    background: #fff;
    transition: bottom 0.3s ease;

    &.success{
      color: @green;
      border-top: 1px solid @grey;
      border-right: 1px solid @grey;
      display: block;
    }
    &.error{
      color: @red;
      border-top: 1px solid @grey;
      border-right: 1px solid @grey;
      display: block;
    }
  }

  &:hover{
    .module--footer{
      height: 50px;
    }
    .message{
      bottom: 50px;
    }
  }
}


.module--style{
  .bloc{
    h3{
      margin: 20px 0 0 0;
      font-size: 16px;

      i{
        font-size: 12px;
      }
    }
  }

  .form-control--small{
    border-radius: 3px;
    padding: 0 10px;
    height: 20px !important;
    min-width: 90px;
    //text-transform: uppercase;

  }


  .file-upload{
    margin-top: -5px;
    @media(max-width: @screen-desktop){
      margin-top: 10px;
      margin-right: 6px;
    }
  }

}

.module--style{
  display: none;
  padding-bottom: 60px;
  border-top: 1px solid @grey;
}



.module--image{

  border: 2px dashed #fff;
  transition: background 0.3s ease;
  background: #fff;
  position: relative;

  &:not('.slider'){
    &.content{
      height: 250px;
    }
  }


  &.with-text{
    height: 345px;
    padding-top: 60px;
    padding-left: 20px;
  }

  span{
    line-height: 200px;
    font-weight: bold;
  }


  p{
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
  }

  label{
    display: block;
    text-align: center;
    width: 200px;
    margin: 80px auto;
  }

  .parameters {
    padding-top: 30px;
    padding-bottom: 40px;

    label {
      width: auto;
      margin: 0;
      text-align: left;
    }
  }


  &.dz-drag-hover{
    border: 2px dashed @blue;
    background: fade(@blue,35%);
  }

  &.slider{
    margin-bottom: 40px;
  }

}

.module--image,.module--boxed {
  i{
    font-size: 100px;
    text-align: center;
    display: block;
    margin-top: 30px;


    &.icon{
      cursor: pointer;
      font-size: 20px;
      position: absolute;
      margin: 0;
      background: #fff;
      padding: 5px;
      border: 1px solid @grey;
      &.remove-img{
        right: -1px;
        top: -1px;
      }
      &.minus{
        left: -1px;
        bottom: -1px;
      }
      &.plus{
        right: -1px;
        bottom: -1px;
      }
      &.fa-trash {
        top: 0;
        right: 0;
        border-right: none;
        border-top: none;
      }

    }
  }
}



.bloc{
  margin: 0 15px 15px 15px;

  .bloc--title{
    margin-bottom: 5px;
  }
}

.hr--container{
  height: 100px;

  hr{
    margin: 80px 15px;
    border-top: 4px solid @dark-grey;
  }
}

.module--content{
  padding: 20px 15px 65px 15px;


  &.is-video{
    min-height: 200px;
    padding-top: 45px;
  }
}



.style-group{
  margin-bottom: 25px;
}


.is-video{
  .btn-secondary{
    margin-top: 0;
  }
}


.gallery--element{
  height: 600px;
  padding: 15px 15px 45px 15px;
  border: 1px solid @grey;
  margin: 5px;
  position: relative;

  .img-container{
    text-align: center;
    position: relative;
    height: 150px;
    line-height: 150px;
    img{
      max-height: 100%;
      max-width: 100%;
    }

    label{
      margin: 0;
    }
  }

  &.gratter{
    height: 230px;
  }


  .icon {
    position: absolute;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: block;
    line-height: 30px;
    text-align: center;
    border: 1px solid #c3cbce;
    z-index: 9999;
    background: #fff;


    &.fa-trash {
      top: 0;
      right: 0;
      border-right: none;
      border-top: none;
    }

    &.fa-pencil {
      top: 0;
      left: 0;
      border-left: none;
      border-top: none;
    }

  }

}

.gallery{
  .add-an-image{
    height: 450px;
    line-height: 450px;
    text-align: center;
  }
}



small{
  display: block;
}

.mce-container-body {
  border-right: 1px solid #f0f0f0;
}


.module-img-description{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  background: #fff;
  padding: 5px;
  width: 50px;
  border-bottom: 1px solid @grey;
  border-left: 1px solid @grey;
}

.boxed {
  border: 1px solid @grey;
  padding: 15px;
  position: relative;
}