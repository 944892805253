.cp-container {
  width: auto;
  position: relative;
  box-sizing: border-box;
}
.cp-container .cp-swatches .cp-swatch,
.cp-container .cp-transparency {
  background: url(data:image/gif;base64,R0lGODlhBgAGAIABAMPDw////yH5BAEKAAEALAAAAAAGAAYAAAIKhIMGGMrs0pGgAAA7) repeat;
}
.cp-container .cp-preview,
.cp-container .cp-slider {
  width: auto;
  height: 26px;
  border: 1px solid #dadada;
  margin: 0 4px;
  margin-bottom: 5pt;
  position: relative;
}
.cp-container .cp-sliderbutton {
  color: #585858;
  text-align: center;
  font-size: 15px;
  font-weight: bolder;
  width: 18px;
  margin: 0;
  cursor: pointer;
  top: 1px;
  line-height: 24px;
}
.cp-container .cp-sliderbutton-minus {
  float: left;
  position: relative;
  left: -20px;
}
.cp-container .cp-sliderbutton-plus {
  float: right;
  position: relative;
  left: 20px;
}
.cp-container .cp-hsvpanel {
  width: auto;
  height: auto;
  border: none;
  margin-right: -6px;
}
.cp-container .cp-hsvpanel .cp-hsvpanel-a,
.cp-container .cp-hsvpanel .cp-hsvpanel-h,
.cp-container .cp-hsvpanel .cp-hsvpanel-sv {
  border: 1px solid #dadada;
  display: inline-block;
  position: relative;
  margin: 0 8px 0 4px;
}
.cp-container .cp-hsvpanel .cp-hsvpanel-sv {
  width: 122px;
  height: 122px;
  cursor: default;
}
.cp-container .cp-hsvpanel .cp-hsvpanel-a,
.cp-container .cp-hsvpanel .cp-hsvpanel-h {
  width: 30px;
  height: 122px;
  cursor: ns-resize;
}
.cp-container.cp-container-sm .cp-hsvpanel .cp-hsvpanel-a,
.cp-container.cp-container-sm .cp-hsvpanel .cp-hsvpanel-h {
  width: 27px;
}
.cp-container .cp-hsvpanel .cp-hsvpanel-h {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBzdG9wLWNvbG9yPSIjZmYwMDAwIiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMCIvPjxzdG9wIHN0b3AtY29sb3I9IiNmZmZmMDAiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwLjE2Njk5OTk5OTk5OTk5OTk4Ii8+PHN0b3Agc3RvcC1jb2xvcj0iIzAwZmYwMCIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjAuMzMyOTk5OTk5OTk5OTk5OTYiLz48c3RvcCBzdG9wLWNvbG9yPSIjMDBmZmZmIiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMC41Ii8+PHN0b3Agc3RvcC1jb2xvcj0iIzAwMDBmZiIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjAuNjY3Ii8+PHN0b3Agc3RvcC1jb2xvcj0iI2ZmMDBmZiIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjAuODMzIi8+PHN0b3Agc3RvcC1jb2xvcj0iI2ZmMDAwMCIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjEiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdnNnZykiIC8+PC9zdmc+);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(0, red), color-stop(0.167, #ff0), color-stop(0.333, #0f0), color-stop(0.5, #0ff), color-stop(0.667, #00f), color-stop(0.833, #f0f), color-stop(1, red));
  background-image: -webkit-linear-gradient(top, red 0, #ff0 16.7%, #0f0 33.3%, #0ff 50%, #00f 66.7%, #f0f 83.3%, red 100%);
  background-image: linear-gradient(to bottom, red 0, #ff0 16.7%, #0f0 33.3%, #0ff 50%, #00f 66.7%, #f0f 83.3%, red 100%);
}
.cp-container .cp-hsvpanel .cp-hsvpanel-a span,
.cp-container .cp-hsvpanel .cp-hsvpanel-sv span {
  display: block;
  width: 100%;
  height: 100%;
}
.cp-container .cp-hsvpanel .cp-hsvpanel-sv span {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjEwMCUiIHgyPSIwJSIgeTI9IjAlIj48c3RvcCBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMCIvPjxzdG9wIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMCIgb2Zmc2V0PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI3ZzZ2cpIiAvPjwvc3ZnPg==), url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjAlIj48c3RvcCBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMCIvPjxzdG9wIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMCIgb2Zmc2V0PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI3ZzZ2cpIiAvPjwvc3ZnPg==);
  background-image: -webkit-gradient(linear, 0 100%, 0 0, color-stop(0, #000), color-stop(1, rgba(0, 0, 0, 0))), -webkit-gradient(linear, 0 0, 100% 0, color-stop(0, #fff), color-stop(1, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(bottom, #000 0, rgba(0, 0, 0, 0) 100%), -webkit-linear-gradient(left, #fff 0, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to top, #000 0, rgba(0, 0, 0, 0) 100%), linear-gradient(to right, #fff 0, rgba(255, 255, 255, 0) 100%);
}
.cp-container .cp-slider {
  cursor: ew-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cp-container .cp-preview {
  height: 39px;
}
.cp-container .cp-preview input,
.cp-container .cp-slider span {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: sans-serif;
  line-height: 26px;
  font-size: 14px;
}
.cp-container .cp-preview input {
  line-height: 39px;
  padding: 0;
  outline: 0;
  box-shadow: none;
  border: none;
  border-radius: 0;
  background: 0 0;
}
.cp-container .cp-marker {
  position: absolute;
  display: block;
  width: 11px;
  height: 10px;
  margin-left: -5px;
  top: -2px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAKCAMAAABVLlSxAAAAtFBMVEUAAAB1dXVsbGxwcHBqampoaGj+/v7r6+vw8PBmZmb6+vp/f39kZGTh4eGxsbHT09NlZWXX19dgYGBfX1/W1tb7+/vx8fHZ2dna2tqBgYFnZ2fg4ODe3t7j4+Pi4uL39/eampro6Ojl5eXy8vLt7e3p6emgoKCmpqatra1ra2v19fXm5ubz8/Pv7+9hYWFjY2N5eXlvb29zc3Ps7Ozd3d2ysrKCgoKDg4P9/f2zs7P4+Pj///83Bdu/AAAAAXRSTlMAQObYZgAAAGlJREFUeNo9ylcbgWAAhuG3ZZQoEkkhDe099P3//9Xg6j56Dh6cjkm623IsnTNIDsqlEsN1HBVI5evdDMtN0FOQKt14lWrArzLcnpZjvx9nshcA342jviO1hlHuTdlgVnzIt8VfNubiNwzmhAsoIAkssgAAAABJRU5ErkJggg==);
}
.cp-container .cp-hsvmarker-vertical {
  position: absolute;
  display: block;
  width: 10px;
  height: 11px;
  margin-top: -5px;
  right: -2px;
  top: 0;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAALCAMAAABxsOwqAAAAbFBMVEUAAACysrLz8/NjY2Pw8PBmZmbe3t7////4+PiBgYHs7Oxra2t0dHSDg4Ozs7P9/f3p6enj4+Pa2trh4eFhYWFfX1/X19d5eXlvb2/7+/vm5ub19fXt7e2tra2mpqagoKCamprl5eXT09NwcHAw/sWYAAAAAXRSTlMAQObYZgAAAFtJREFUCNclzNsWQCAURdFDF1RIpZI7//+P6thP82GPBTjGiJtR8uL+Qcbztsa7LHVQPXFDAMLe1pu2nEA3NGNbi2QZQF9MRWKQXTXLSoUshQ6dic73CL+DUu8HF6YEcUMoNOYAAAAASUVORK5CYII=);
}
.cp-container .cp-marker-point {
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  margin-left: -5px;
  margin-top: -5px;
  left: 0;
  top: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKBAMAAAB/HNKOAAAALVBMVEX///8AAADX19d7e3t4eHhDQ0NnZ2dzc3NLS0tbW1tTU1POzs7AwMC0tLSoqKgWXydUAAAAAnRSTlN1ACTbQIsAAAA/SURBVAjXYxAUDBcUZBCUeOQIJNcyqAgySB4QZDJkyGYUFFBmMAKS2xiEFQR5JjIIOjHcAqoR0WsEkoKlgoIAHu8JgCiLy9kAAAAASUVORK5CYII=);
}
.cp-container.cp-unconvertible-cie-color .cp-slider.cp-ciechroma .cp-marker,
.cp-container.cp-unconvertible-cie-color .cp-slider.cp-ciehue .cp-marker,
.cp-container.cp-unconvertible-cie-color .cp-slider.cp-cielightness .cp-marker {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAKCAMAAABVLlSxAAAAvVBMVEUAAADLy8u8vLzZ2dlgYGDe3t5sbGxoaGjr6+vb29tqamrCwsJmZmZkZGTW1tbExMTw8PDj4+Pa2tpfX1/z8/PU1NTp6enX19fg4OBwcHDc3NzJycn4+Pj7+/v6+vrd3d3h4eH+/v6lpaWmpqaampqgoKBlZWWzs7Orq6tra2uxsbGBgYF1dXVnZ2d/f3/T09PMzMxhYWFjY2P19fXv7+9vb2/l5eVzc3P////n5+eysrKDg4OCgoJ5eXnoAACu7h75AAAAAXRSTlMAQObYZgAAAG1JREFUeNo1ytsaQkAAReGdkpRKRUTofEANBoOY93+sfMp/tS4WNqM4mSvSdJztEJPFkETyJNUYEmMtzvhApfsQN89cCXxJ3WOD1yXfRlx3Cv8NPK+pZedFFaCVnQ+8rGp02Kl8fPDX3Nvs/YYvAnQLhiGwga0AAAAASUVORK5CYII=);
}
.cp-container .cp-swatches ul {
  width: auto;
  margin: -7px -7px 0 0;
  padding: 0;
  list-style-type: none;
}
.cp-container .cp-swatches li {
  float: left;
  margin: 7px 7px 0 0;
}
.cp-swatches button {
  float: left;
  margin: 7px 7px 0 0;
  width: 26px;
  height: 26px;
  display: inline-block;
  font-size: 12px;
  padding: 0;
}
.cp-container .cp-swatch {
  width: 26px;
  height: 26px;
  display: inline-block;
  cursor: pointer;
  border-color: #ccc;
  position: relative;
  overflow: hidden;
  outline: 0;
  padding: 0;
}
.cp-container .cp-swatch span {
  display: block;
  width: 100%;
  height: 100%;
}
.cp-container .cp-swatches .cp-swatch.actual {
  border-color: #777;
  -webkit-box-shadow: 0 0 5px #fa8000;
  -moz-box-shadow: 0 0 5px #fa8000;
  box-shadow: 0 0 5px #fa8000;
}
.cp-popover-container .popover {
  max-width: 1000px;
}
.popover-content .cp-container {
  width: 263px;
}
.popover-content .cp-container.cp-container-sm {
  width: 208px;
}
.popover-content .cp-container.cp-container-sm .cp-swatch,
.popover-content .cp-container.cp-container-sm .cp-swatches button {
  width: 23px;
  height: 23px;
}
.popover-content .cp-container.cp-container-lg {
  width: 369px;
}
.popover-content .cp-container.cp-container-lg .cp-swatch,
.popover-content .cp-container.cp-container-lg .cp-swatches button {
  width: 27px;
  height: 27px;
}
.cp-container .cp-pills {
  display: none;
}
.cp-container .cp-pills {
  display: inline-block;
}
.cp-container .cp-pills {
  list-style-type: none;
  margin-bottom: 5px;
  padding: 0;
  font-size: 12px;
}
.cp-container .cp-pills li {
  display: inline-block;
  margin: 3px;
  padding: 3px 0;
}
.cp-container .cp-pills li a {
  padding: 5px;
  color: #000;
  text-decoration: none;
}
.cp-container .cp-pills li a.active,
.cp-container .cp-pills li a:hover {
  background-color: #02afff;
  color: #fff;
}
.cp-container.hsvpanel-active .cp-sliders,
.cp-container.hsvpanel-active .cp-swatches {
  display: none;
}
.cp-container.sliders-active .cp-hsvpanel,
.cp-container.sliders-active .cp-swatches {
  display: none;
}
.cp-container.swatches-active .cp-hsvpanel,
.cp-container.swatches-active .cp-sliders {
  display: none;
}
.cp-popover-container .cp-hsvpanel,
.cp-popover-container .cp-sliders {
  margin-top: 7px;
}
.form-control[readonly].cp-preventtouchkeyboardonshow {
  cursor: text;
}
.popover-content .cp-container.hsvpanel-active {
  width: 220px;
}
/* BASICS */
.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  height: 300px;
  color: black;
  direction: ltr;
}
/* PADDING */
.CodeMirror-lines {
  padding: 4px 0;
  /* Vertical padding around content */
}
.CodeMirror pre {
  padding: 0 4px;
  /* Horizontal padding of content */
}
.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  background-color: white;
  /* The little square between H and V scrollbars */
}
/* GUTTER */
.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap;
}
.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
}
.CodeMirror-guttermarker {
  color: black;
}
.CodeMirror-guttermarker-subtle {
  color: #999;
}
/* CURSOR */
.CodeMirror-cursor {
  border-left: 1px solid black;
  border-right: none;
  width: 0;
}
/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}
.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: #7e7;
}
.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}
.cm-fat-cursor-mark {
  background-color: rgba(20, 255, 20, 0.5);
  -webkit-animation: blink 1.06s steps(1) infinite;
  -moz-animation: blink 1.06s steps(1) infinite;
  animation: blink 1.06s steps(1) infinite;
}
.cm-animate-fat-cursor {
  width: auto;
  border: 0;
  -webkit-animation: blink 1.06s steps(1) infinite;
  -moz-animation: blink 1.06s steps(1) infinite;
  animation: blink 1.06s steps(1) infinite;
  background-color: #7e7;
}
@-moz-keyframes blink {
  50% {
    background-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    background-color: transparent;
  }
}
@keyframes blink {
  50% {
    background-color: transparent;
  }
}
/* Can style cursor different in overwrite (non-insert) mode */
.cm-tab {
  display: inline-block;
  text-decoration: inherit;
}
.CodeMirror-rulers {
  position: absolute;
  left: 0;
  right: 0;
  top: -50px;
  bottom: -20px;
  overflow: hidden;
}
.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  top: 0;
  bottom: 0;
  position: absolute;
}
/* DEFAULT THEME */
.cm-s-default .cm-header {
  color: blue;
}
.cm-s-default .cm-quote {
  color: #090;
}
.cm-negative {
  color: #d44;
}
.cm-positive {
  color: #292;
}
.cm-header,
.cm-strong {
  font-weight: bold;
}
.cm-em {
  font-style: italic;
}
.cm-link {
  text-decoration: underline;
}
.cm-strikethrough {
  text-decoration: line-through;
}
.cm-s-default .cm-keyword {
  color: #708;
}
.cm-s-default .cm-atom {
  color: #219;
}
.cm-s-default .cm-number {
  color: #164;
}
.cm-s-default .cm-def {
  color: #00f;
}
.cm-s-default .cm-variable-2 {
  color: #05a;
}
.cm-s-default .cm-variable-3,
.cm-s-default .cm-type {
  color: #085;
}
.cm-s-default .cm-comment {
  color: #a50;
}
.cm-s-default .cm-string {
  color: #a11;
}
.cm-s-default .cm-string-2 {
  color: #f50;
}
.cm-s-default .cm-meta {
  color: #555;
}
.cm-s-default .cm-qualifier {
  color: #555;
}
.cm-s-default .cm-builtin {
  color: #30a;
}
.cm-s-default .cm-bracket {
  color: #997;
}
.cm-s-default .cm-tag {
  color: #170;
}
.cm-s-default .cm-attribute {
  color: #00c;
}
.cm-s-default .cm-hr {
  color: #999;
}
.cm-s-default .cm-link {
  color: #00c;
}
.cm-s-default .cm-error {
  color: #f00;
}
.cm-invalidchar {
  color: #f00;
}
.CodeMirror-composing {
  border-bottom: 2px solid;
}
/* Default styles for common addons */
div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0;
}
div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22;
}
.CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3);
}
.CodeMirror-activeline-background {
  background: #e8f2ff;
}
/* STOP */
/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */
.CodeMirror {
  position: relative;
  overflow: hidden;
  background: white;
}
.CodeMirror-scroll {
  overflow: scroll !important;
  /* Things will break if this is overridden */
  /* 30px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -30px;
  margin-right: -30px;
  padding-bottom: 30px;
  height: 100%;
  outline: none;
  /* Prevent dragging from highlighting the element */
  position: relative;
}
.CodeMirror-sizer {
  position: relative;
  border-right: 30px solid transparent;
}
/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actual scrolling happens, thus preventing shaking and
   flickering artifacts. */
.CodeMirror-vscrollbar,
.CodeMirror-hscrollbar,
.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none;
}
.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}
.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}
.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0;
}
.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0;
}
.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  z-index: 3;
}
.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -30px;
}
.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: none !important;
  border: none !important;
}
.CodeMirror-gutter-background {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4;
}
.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4;
}
.CodeMirror-gutter-wrapper ::selection {
  background-color: transparent;
}
.CodeMirror-gutter-wrapper ::-moz-selection {
  background-color: transparent;
}
.CodeMirror-lines {
  cursor: text;
  min-height: 1px;
  /* prevents collapsing before first draw */
}
.CodeMirror pre {
  /* Reset some styles that the rest of the page might have set */
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual;
}
.CodeMirror-wrap pre {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}
.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}
.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  padding: 0.1px;
  /* Force widget margins to stay inside of the container */
}
.CodeMirror-rtl pre {
  direction: rtl;
}
.CodeMirror-code {
  outline: none;
}
/* Force content-box sizing for the elements where we expect it */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
.CodeMirror-cursor {
  position: absolute;
  pointer-events: none;
}
.CodeMirror-measure pre {
  position: static;
}
div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3;
}
div.CodeMirror-dragcursors {
  visibility: visible;
}
.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}
.CodeMirror-selected {
  background: #d9d9d9;
}
.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}
.CodeMirror-crosshair {
  cursor: crosshair;
}
.CodeMirror-line::selection,
.CodeMirror-line > span::selection,
.CodeMirror-line > span > span::selection {
  background: #d7d4f0;
}
.CodeMirror-line::-moz-selection,
.CodeMirror-line > span::-moz-selection,
.CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0;
}
.cm-searching {
  background-color: #ffa;
  background-color: rgba(255, 255, 0, 0.4);
}
/* Used to force a border model for a node */
.cm-force-border {
  padding-right: .1px;
}
@media print {
  /* Hide the cursor when printing */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}
/* See issue #2901 */
.cm-tab-wrap-hack:after {
  content: '';
}
/* Help users use markselection to safely style text background */
span.CodeMirror-selectedtext {
  background: none;
}
.croppie-container {
  width: 100%;
  height: 100%;
}
.croppie-container .cr-image {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0 0;
  max-height: none;
  max-width: none;
}
.croppie-container .cr-boundary {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  position: absolute;
  border: 2px solid #fff;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, 0.5);
  z-index: 0;
}
.croppie-container .cr-resizer {
  z-index: 2;
  box-shadow: none;
  pointer-events: none;
}
.croppie-container .cr-resizer-vertical,
.croppie-container .cr-resizer-horisontal {
  position: absolute;
  pointer-events: all;
}
.croppie-container .cr-resizer-vertical::after,
.croppie-container .cr-resizer-horisontal::after {
  display: block;
  position: absolute;
  box-sizing: border-box;
  border: 1px solid black;
  background: #fff;
  width: 10px;
  height: 10px;
  content: '';
}
.croppie-container .cr-resizer-vertical {
  bottom: -5px;
  cursor: row-resize;
  width: 100%;
  height: 10px;
}
.croppie-container .cr-resizer-vertical::after {
  left: 50%;
  margin-left: -5px;
}
.croppie-container .cr-resizer-horisontal {
  right: -5px;
  cursor: col-resize;
  width: 10px;
  height: 100%;
}
.croppie-container .cr-resizer-horisontal::after {
  top: 50%;
  margin-top: -5px;
}
.croppie-container .cr-original-image {
  display: none;
}
.croppie-container .cr-vp-circle {
  border-radius: 50%;
}
.croppie-container .cr-overlay {
  z-index: 1;
  position: absolute;
  cursor: move;
  touch-action: none;
}
.croppie-container .cr-slider-wrap {
  width: 75%;
  margin: 15px auto;
  text-align: center;
}
.croppie-result {
  position: relative;
  overflow: hidden;
}
.croppie-result img {
  position: absolute;
}
.croppie-container .cr-image,
.croppie-container .cr-overlay,
.croppie-container .cr-viewport {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
/*************************************/
/***** STYLING RANGE INPUT ***********/
/*************************************/
/*http://brennaobrien.com/blog/2014/05/style-input-type-range-in-every-browser.html */
/*************************************/
.cr-slider {
  -webkit-appearance: none;
  /*removes default webkit styles*/
  /*border: 1px solid white; */
  /*fix for FF unable to apply focus style bug */
  width: 300px;
  /*required for proper track sizing in FF*/
  max-width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: transparent;
}
.cr-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 3px;
}
.cr-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ddd;
  margin-top: -6px;
}
.cr-slider:focus {
  outline: none;
}
/*
.cr-slider:focus::-webkit-slider-runnable-track {
background: #ccc;
}
*/
.cr-slider::-moz-range-track {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 3px;
}
.cr-slider::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ddd;
  margin-top: -6px;
}
/*hide the outline behind the border*/
.cr-slider:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}
.cr-slider::-ms-track {
  width: 100%;
  height: 5px;
  background: transparent;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  border-color: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-width: 6px 0;
  color: transparent;
  /*remove default tick marks*/
}
.cr-slider::-ms-fill-lower {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.cr-slider::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.cr-slider::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ddd;
  margin-top: 1px;
}
.cr-slider:focus::-ms-fill-lower {
  background: rgba(0, 0, 0, 0.5);
}
.cr-slider:focus::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.5);
}
/*******************************************/
/***********************************/
/* Rotation Tools */
/***********************************/
.cr-rotate-controls {
  position: absolute;
  bottom: 5px;
  left: 5px;
  z-index: 1;
}
.cr-rotate-controls button {
  border: 0;
  background: none;
}
.cr-rotate-controls i:before {
  display: inline-block;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
}
.cr-rotate-l i:before {
  content: '↺';
}
.cr-rotate-r i:before {
  content: '↻';
}
button.button-circle {
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  border: none;
  transition: background 0.3s ease;
  background: #55bbf6;
}
button.button-circle:hover {
  background: #0d9ef2;
}
button.button-circle--grey {
  transition: background 0.3s ease;
  background: #59676c;
}
button.button-circle--grey:hover {
  background: #363f42;
}
.btn.btn-mini {
  height: 25px;
  width: 25px;
  padding: 0;
  font-size: 11px;
  color: #59676c;
  background: #fff;
}
.btn.btn-mini.active {
  background: #c3cbce;
}
@media (max-width: 992px) {
  .btn-group .btn {
    padding: 5px;
    font-size: 11px;
  }
}
.alert .btn.level {
  border-radius: 50px;
  background-color: #fff;
  color: #000;
}
.mg-left--15 {
  margin-left: -15px;
}
.pd-0 {
  padding: 0;
}
.pd-15 {
  padding: 15px;
}
.pd-right-0 {
  padding-right: 0;
}
.mg-top-15 {
  margin-top: 15px;
}
label.label--file .text {
  padding: 0 10px;
  background: #fff;
  border: 1px solid #c3cbce;
  width: 120px;
  display: block;
  float: left;
  height: 20px;
  border-radius: 10px 0 0 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
label.label--file .button {
  cursor: pointer;
  width: 80px;
  float: left;
  height: 20px;
  border-radius: 0 10px 10px 0;
  background: #414141;
  color: #fff;
  padding: 0 10px;
  font-size: 12px;
  line-height: 20px;
}
.label-input {
  font-size: 12px;
  margin: 10px 0 0 0;
  display: block;
  font-weight: 400;
  font-style: italic;
}
.pointer {
  cursor: pointer;
}
.red {
  color: #F44336;
}
.mg-top--20 {
  margin-top: -20px;
}
.pd-l-15 {
  padding-left: 15px;
}
.mg-top-30 {
  margin-top: 30px;
}
.mg-0 {
  margin: 0;
}
input[type="range"] {
  position: relative;
  margin-left: 0;
  border: none;
  box-shadow: none;
  padding: 0;
}
input[type="range"]:after,
input[type="range"]:before {
  position: absolute;
  top: 0.5em;
  color: #aaa;
}
input[type="range"]:before {
  left: -2em;
  content: attr(min);
}
input[type="range"]:after {
  right: -2em;
  content: attr(max);
}
input[type="range"] + output {
  text-align: center;
}
.notice {
  padding: 5px 15px;
  margin-bottom: 0;
  font-size: 12px;
  font-style: italic;
}
.text-underline {
  text-decoration: underline;
}
.mce-floatpanel {
  z-index: 99999 !important;
}
.no-style {
  list-style-type: none;
  padding-left: 0;
}
html {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -moz-box-sizing: inherit;
  -webkit-box-sizing: inherit;
  -ms-box-sizing: inherit;
  box-sizing: inherit;
}
.plain-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 14px;
  font-weight: 400;
}
.ie8 .plain-select select {
  padding-right: 3px;
}
.plain-select ::-ms-expand {
  display: none;
}
.plain-select:after {
  content: "";
  position: absolute;
  z-index: 2;
  right: 8px;
  top: 50%;
  margin-top: -3px;
  height: 0;
  width: 0;
  border-top: 6px solid #000;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  pointer-events: none;
}
.input-inline {
  height: 40px;
  line-height: 40px;
}
.input-inline select,
.input-inline input {
  float: right;
  width: 70%;
}
/** @use choixmodelesmartpage.php **/
div.model {
  margin: 15px 0;
}
div.model h4 {
  text-align: center;
  font-size: 13px;
  margin: 5px 0 0 0;
  font-weight: bold;
}
div.model p {
  text-align: center;
  font-size: 11px;
}
div.model .buttons {
  text-align: center;
}
div.model .buttons button {
  margin: 0 5px;
}
a.model {
  height: 150px;
  width: 100%;
  border: none;
  text-align: center;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  font-family: 'yanone_kaffeesatzregular', Arial, sans-serif;
  letter-spacing: 1px;
  cursor: pointer;
  line-height: 150px;
  padding: 0 10px;
  display: block;
  transition: background 0.3s ease;
  background: #55bbf6;
}
a.model input[type="radio"] {
  position: absolute;
  opacity: 0;
  left: -999px;
  top: -999px;
}
a.model:hover {
  background: #0d9ef2;
}
a.model i {
  display: block;
  font-size: 80px;
  margin-bottom: 5px;
  line-height: initial;
  padding-top: 20px;
}
a.model span {
  color: #fff;
}
a.model img {
  display: block;
  margin: 0 auto;
  padding: 20px 20px 10px 20px;
  max-height: 100px;
  max-width: 100%;
}
a.model i + span,
a.model img + span {
  line-height: initial;
  display: block;
}
input[type="radio"]:checked + label.model {
  background: #0c8ed9;
}
.model-img {
  border: 1px solid lightgrey;
  padding: 15px;
  width: 100%;
  margin-bottom: 10px;
}
.model-img img {
  height: auto;
  max-height: 110px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  padding: 20px;
  background: #55bbf6;
}
.card-content {
  background: #fff;
}
.smartpage-bandeau {
  background: #c3cbce;
  height: 80px;
}
.smartpage-bandeau .cancel {
  line-height: 80px;
  padding: 0 15px;
}
.smartpage-bandeau .cancel span {
  font-family: 'yanone_kaffeesatzregular', Arial, sans-serif;
  font-size: 14px;
}
.smartpage-bandeau .cancel button[disabled] {
  background: #8b9aa0;
  cursor: not-allowed;
}
.smartpage-bandeau .section {
  height: 80px;
  border-right: 1px solid #fff;
}
.smartpage-bandeau .btn-group {
  padding-top: 18px;
}
.smartpage-bandeau .btn-group label,
.smartpage-bandeau .btn-group a {
  font-family: 'yanone_kaffeesatzregular', Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.smartpage-bandeau .btn-save {
  margin: 20px 15px;
}
body .smartpage-bandeau-bleu {
  background-color: #065993;
  color: #fff;
}
body .smartpage-bandeau-bleu h2 {
  color: #fff;
  text-transform: uppercase;
  text-align: right;
}
body .smartpage-bandeau-bleu input[type='text'] {
  width: 50%;
  margin-left: 10px;
  margin-right: 10px;
}
body .smartpage-bandeau-bleu .error-editor {
  text-align: left;
}
body .smartpage-bandeau-bleu a {
  display: block;
  text-align: left;
  padding-left: 15px;
  margin-top: 20px;
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  font-family: 'yanone_kaffeesatzregular', Arial, sans-serif;
}
body .smartpage-bandeau-bleu a i {
  margin-top: -6px;
  margin-right: 10px;
  font-size: 30px;
  color: #c7c7c7;
}
.module-selector {
  border: 2px solid #c3cbce;
  height: 120px;
  display: block;
  text-align: center;
  margin: 15px 0;
  color: #59676c;
  background: #fff;
  cursor: grab;
}
.module-selector img {
  line-height: 120px;
  display: block;
  margin: 20px auto 0 auto;
  vertical-align: middle;
  max-width: 60%;
}
.module-selector hr {
  margin: 30px 10px;
  border-top: 5px solid #59676c;
}
.module-selector p {
  text-align: center;
  color: #59676c;
  position: absolute;
  bottom: 0;
  left: 3px;
  right: 3px;
  margin-bottom: 3px;
}
.module--container {
  border: 1px solid #c3cbce;
  margin: 15px 0;
  position: relative;
}
.module--container textarea {
  width: 100%;
  height: 200px;
  text-align: left;
  padding: 10px;
  resize: none;
}
.module--container textarea.small {
  height: 100px;
}
.module--container .module-radio textarea.form-control {
  border: 1px solid #c3cbce;
  height: 150px;
}
.module--container .module--footer {
  background: rgba(195, 203, 206, 0.6);
  padding: 0 15px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 0;
  transition: height 0.3s ease;
  overflow: hidden;
  z-index: 20;
}
.module--container .module--footer .btn {
  padding: 5px 20px;
  height: 30px;
  margin: 10px 10px 0 0;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .module--container .module--footer .btn {
    margin: 10px 5px 0 0;
    padding: 5px 9px;
  }
  .module--container .module--footer .btn.btn-primary {
    min-width: 120px;
  }
}
.module--container .module--footer .btn-group {
  padding-top: 10px;
}
.module--container .message {
  height: 25px;
  line-height: 25px;
  padding: 0 15px;
  display: none;
  position: absolute;
  bottom: 0;
  z-index: 999;
  font-weight: 800;
  background: #fff;
  transition: bottom 0.3s ease;
}
.module--container .message.success {
  color: #4CAF50;
  border-top: 1px solid #c3cbce;
  border-right: 1px solid #c3cbce;
  display: block;
}
.module--container .message.error {
  color: #F44336;
  border-top: 1px solid #c3cbce;
  border-right: 1px solid #c3cbce;
  display: block;
}
.module--container:hover .module--footer {
  height: 50px;
}
.module--container:hover .message {
  bottom: 50px;
}
.module--style .bloc h3 {
  margin: 20px 0 0 0;
  font-size: 16px;
}
.module--style .bloc h3 i {
  font-size: 12px;
}
.module--style .form-control--small {
  border-radius: 3px;
  padding: 0 10px;
  height: 20px !important;
  min-width: 90px;
}
.module--style .file-upload {
  margin-top: -5px;
}
@media (max-width: 992px) {
  .module--style .file-upload {
    margin-top: 10px;
    margin-right: 6px;
  }
}
.module--style {
  display: none;
  padding-bottom: 60px;
  border-top: 1px solid #c3cbce;
}
.module--image {
  border: 2px dashed #fff;
  transition: background 0.3s ease;
  background: #fff;
  position: relative;
}
.module--image:not('.slider').content {
  height: 250px;
}
.module--image.with-text {
  height: 345px;
  padding-top: 60px;
  padding-left: 20px;
}
.module--image span {
  line-height: 200px;
  font-weight: bold;
}
.module--image p {
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
}
.module--image label {
  display: block;
  text-align: center;
  width: 200px;
  margin: 80px auto;
}
.module--image .parameters {
  padding-top: 30px;
  padding-bottom: 40px;
}
.module--image .parameters label {
  width: auto;
  margin: 0;
  text-align: left;
}
.module--image.dz-drag-hover {
  border: 2px dashed #55bbf6;
  background: rgba(85, 187, 246, 0.35);
}
.module--image.slider {
  margin-bottom: 40px;
}
.module--image i,
.module--boxed i {
  font-size: 100px;
  text-align: center;
  display: block;
  margin-top: 30px;
}
.module--image i.icon,
.module--boxed i.icon {
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  margin: 0;
  background: #fff;
  padding: 5px;
  border: 1px solid #c3cbce;
}
.module--image i.icon.remove-img,
.module--boxed i.icon.remove-img {
  right: -1px;
  top: -1px;
}
.module--image i.icon.minus,
.module--boxed i.icon.minus {
  left: -1px;
  bottom: -1px;
}
.module--image i.icon.plus,
.module--boxed i.icon.plus {
  right: -1px;
  bottom: -1px;
}
.module--image i.icon.fa-trash,
.module--boxed i.icon.fa-trash {
  top: 0;
  right: 0;
  border-right: none;
  border-top: none;
}
.bloc {
  margin: 0 15px 15px 15px;
}
.bloc .bloc--title {
  margin-bottom: 5px;
}
.hr--container {
  height: 100px;
}
.hr--container hr {
  margin: 80px 15px;
  border-top: 4px solid #59676c;
}
.module--content {
  padding: 20px 15px 65px 15px;
}
.module--content.is-video {
  min-height: 200px;
  padding-top: 45px;
}
.style-group {
  margin-bottom: 25px;
}
.is-video .btn-secondary {
  margin-top: 0;
}
.gallery--element {
  height: 600px;
  padding: 15px 15px 45px 15px;
  border: 1px solid #c3cbce;
  margin: 5px;
  position: relative;
}
.gallery--element .img-container {
  text-align: center;
  position: relative;
  height: 150px;
  line-height: 150px;
}
.gallery--element .img-container img {
  max-height: 100%;
  max-width: 100%;
}
.gallery--element .img-container label {
  margin: 0;
}
.gallery--element.gratter {
  height: 230px;
}
.gallery--element .icon {
  position: absolute;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: block;
  line-height: 30px;
  text-align: center;
  border: 1px solid #c3cbce;
  z-index: 9999;
  background: #fff;
}
.gallery--element .icon.fa-trash {
  top: 0;
  right: 0;
  border-right: none;
  border-top: none;
}
.gallery--element .icon.fa-pencil {
  top: 0;
  left: 0;
  border-left: none;
  border-top: none;
}
.gallery .add-an-image {
  height: 450px;
  line-height: 450px;
  text-align: center;
}
small {
  display: block;
}
.mce-container-body {
  border-right: 1px solid #f0f0f0;
}
.module-img-description {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  background: #fff;
  padding: 5px;
  width: 50px;
  border-bottom: 1px solid #c3cbce;
  border-left: 1px solid #c3cbce;
}
.boxed {
  border: 1px solid #c3cbce;
  padding: 15px;
  position: relative;
}
.dropzone {
  border: 4px dashed #55bbf6;
  background: #fff;
  width: 100%;
  height: 250px;
  position: relative;
}
.dz-processing {
  display: none;
}
.img-uploaded {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: #fff;
}
.img-uploaded div.img {
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translate(-50%, 0);
  float: none;
  width: auto;
  height: auto;
}
div.img {
  max-width: 100%;
  width: 30%;
  overflow: hidden;
  height: 130px;
  margin: 5px 1.5%;
  max-height: 180px;
  position: relative;
  border: 1px solid #c3cbce;
  float: left;
}
div.img img {
  height: auto;
  max-width: 100%;
  max-height: 180px;
  margin: auto;
}
div.img img.double {
  max-height: 320px;
}
.cp-hsvpanel-a {
  display: none !important;
}
.cp-container.hsvpanel-active {
  width: 170px !important;
}
.sk-circle {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sk-circle--container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 99;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}
@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.mobile--preview {
  background-image: url("../../img/mobile2.png");
  width: 370px;
  height: 715px;
  position: relative;
  background-size: contain;
  margin: 10px auto;
}
.mobile--preview iframe {
  width: 301px;
  height: 485px;
  position: absolute;
  left: 35px;
  top: 99px;
  background: #fff;
}
.mobile--preview.small {
  width: 222px;
  height: 429px;
}
.mobile--preview.small iframe {
  transform: scale(0.6);
  left: -39px;
  top: -37px;
}
.modal--background {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.17);
  z-index: 9999;
}
.modal--background .modal--container {
  margin: 40px auto;
  width: 400px;
  background: #fff;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.modal--background .modal--content {
  padding: 15px;
}
.modal--background .close {
  margin-top: 5px;
  margin-right: 5px;
}
.modal--container.big {
  width: 800px;
  max-width: 90%;
}
.modal--header {
  width: 100%;
  padding: 7px 15px;
}
.modal--header h2 {
  margin: 0;
}
.modal--footer {
  padding: 7px 15px;
  border-top: 1px solid #cdcdcd;
  text-align: right;
}
.resizer {
  width: 800px;
  height: 700px;
  overflow: hidden;
}
.resizer .cr-boundary {
  width: 600px !important;
  height: 600px !important;
}
.no-content {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin-bottom: 0;
  background: rgba(85, 187, 246, 0.35);
}
.no-content .text {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  display: block;
  background: transparent;
  position: absolute;
}
.no-content.active {
  transition: background 0.4s ease;
  background: transparent;
}
.smartpage {
  position: relative;
  margin: 15px 0 0 15px;
  min-height: 400px;
  padding-bottom: 250px;
}
.error-editor {
  padding: 16px 0;
  position: relative;
  height: 72px;
}
.error-editor span {
  position: absolute;
  letter-spacing: 0;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 16px;
  font-weight: bold;
  background: transparent;
  letter-spacing: 1px;
}
.error-editor span.error {
  color: #F44336;
}
.error-editor span.success {
  color: #4CAF50;
}
.card-title--small {
  padding: 16px 0 16px 15px;
}
.custom_url {
  overflow: hidden;
  text-overflow: ellipsis;
  float: right;
  max-width: 80%;
}
.card-statistics {
  min-height: 250px;
  position: relative;
  margin: 100px 0;
}
.header-link {
  font-size: 16px;
  float: right;
}
.datepicker--container {
  display: inline-block;
  position: relative;
}
.datepicker--container .select--arrow {
  position: absolute;
  top: 0px;
  right: 10px;
  pointer-events: none;
}
.datetimepicker {
  font-size: 18px;
  margin: -5px 5px 0 5px;
  border: 1px solid grey;
  padding: 5px 25px 5px 5px;
  width: 200px;
}
.datetimepicker:after {
  content: "";
}
.stat-box {
  color: #fff;
  padding: 10px 15px 35px 15px;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 1px;
}
.stat-box i {
  position: absolute;
  font-size: 74px;
  right: 20px;
  opacity: 0.25;
}
.stat-box strong {
  display: block;
  margin-top: 10px;
  font-weight: normal;
}
.stat-box.blue {
  background-color: #55bbf6;
}
.stat-box.yellow {
  background-color: #FBC02D;
}
.stat-box.red {
  background-color: #F44336;
}
