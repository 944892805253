.no-content{
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin-bottom: 0;
  background: fade(@blue,35%);
  .text{
    left: 50%;
    top: 50%;
    transform:translate(-50%,-50%);
    width: 280px;
    display: block;
    background: transparent;
    position: absolute;
  }

  &.active{
    transition: background 0.4s ease;
    background: transparent;
  }

}


.smartpage{
  position:relative;
  margin: 15px 0 0 15px;
  min-height: 400px;
  padding-bottom: 250px;
}

.error-editor{
  padding: 16px 0;
  position: relative;
  height: 72px;

  span{
    position: absolute;
    letter-spacing: 0;
    top: 50%;
    transform: translate(0,-50%);
    font-size: 16px;
    font-weight: bold;
    background: transparent;
    letter-spacing: 1px;

    &.error{
      color: @red;
    }
    &.success{
      color: @green;
    }
  }
}

.card-title--small{
  padding: 16px 0 16px 15px;
}

.custom_url {
  overflow: hidden;
  text-overflow: ellipsis;
  float: right;
  max-width: 80%;
}