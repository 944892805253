
.mobile--preview{
  background-image: url("../../img/mobile2.png");
  width: 370px;
  height: 715px;
  position: relative;
  background-size: contain;
  margin: 10px auto;
  iframe{
    width:301px;
    height:485px;
    position:absolute;
    left:35px;
    top:99px;
    background:#fff;
  }

  &.small{
    width: 222px;
    height: 429px;

    iframe {
      transform: scale(0.6);
      left: -39px;
      top: -37px;
    }
  }

}

