label.label--file {

  .text {
    padding: 0 10px;
    background: #fff;
    border: 1px solid @grey;
    width: 120px;
    display: block;
    float: left;
    height: 20px;
    border-radius: 10px 0 0 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .button{
    cursor: pointer;
    width: 80px;
    float: left;
    height: 20px;
    border-radius: 0 10px 10px 0;
    background: @secondary;
    color: #fff;
    padding: 0 10px;
    font-size: 12px;
    line-height: 20px;
  }

}


.label-input{
  font-size: 12px;
  margin: 10px 0 0 0;
  display: block;
  font-weight: 400;
  font-style: italic;
}

.pointer {
  cursor: pointer;
}

.red {
  color: @red;
}

.mg-top--20 {
  margin-top: -20px;
}

.pd-l-15 {
  padding-left: 15px;
}

.mg-top-30 {
  margin-top: 30px;
}

.mg-0 {
  margin: 0;
}

input[type="range"] {
  position: relative;
  margin-left: 0;
  border: none;
  box-shadow: none;
  padding: 0;

  &:after,&:before {
    position: absolute;
    top: 0.5em;
    color: #aaa;
  }

  &:before {
    left:-2em;
    content: attr(min);
  }
  &:after {
    right: -2em;
    content: attr(max);
  }

  + output {
    text-align: center;
  }
}
