.dropzone{
  border: 4px dashed @blue;
  background: #fff;
  width: 100%;
  height: 250px;
  position: relative;
}


.dz-processing{
  display: none;
}

.img-uploaded {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: #fff;

  div.img{
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translate(-50%,0);
    float: none;
    width: auto;
    height: auto;
  }

}

div.img{
  max-width: 100%;
  width: 30%;
  overflow: hidden;
  height: 130px;
  margin: 5px 1.5%;
  max-height: 180px;
  position: relative;
  border: 1px solid @grey;
  float: left;

  img{
    height: auto;
    max-width: 100%;
    max-height: 180px;
    margin: auto;

    &.double{
      max-height: 320px;
    }
  }
}


