html {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
*, *:before, *:after {
  -moz-box-sizing: inherit;
  -webkit-box-sizing: inherit;
  -ms-box-sizing: inherit;
  box-sizing: inherit;
}
.plain-select  {
  display:inline-block;
  vertical-align:middle;
  position:relative;
  font-size: 14px;
  font-weight: 400;
}


.ie8 .plain-select select{padding-right:3px}



.plain-select ::-ms-expand {
  display:none;
}
.plain-select:after{
  content:"";
  position:absolute;
  z-index:2;
  right:8px;
  top:50%;
  margin-top:-3px;
  height:0;
  width:0;
  border-top:6px solid #000;
  border-left:6px solid transparent;
  border-right:6px solid transparent;
  pointer-events:none;
}

.input-inline {
  height: 40px;
  line-height: 40px;
  select, input {
    float: right;
    width: 70%;
  }
}