@blue : #55bbf6;

@dark-blue : #065993;
@grey : #c3cbce;

@secondary : #414141;

@red : #F44336;
@green : #4CAF50;


@yellow : #FBC02D;

@dark-grey : darken(@grey,40);

@main-font : 'yanone_kaffeesatzregular', Arial, sans-serif;


.button_color(@color){
  transition: background 0.3s ease;
  background: @color;

  &:hover{
    background: darken(@color,15);
  }
}
