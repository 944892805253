.mg-left--15{
  margin-left: -15px;
}
.pd-0{
  padding: 0;
}

.pd-15{
  padding: 15px;
}

.pd-right-0{
  padding-right: 0;
}

.mg-top-15 {
  margin-top: 15px;
}