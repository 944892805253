/** @use choixmodelesmartpage.php **/
div.model{

  margin: 15px 0;
  h4{
    text-align: center;
    font-size: 13px;
    margin: 5px 0 0 0;
    font-weight: bold;
  }
  p{
    text-align: center;
    font-size: 11px;
  }

  .buttons{
    text-align: center;
    button{
      margin: 0 5px;
    }
  }
}

a.model{
  height: 150px;
  width: 100%;
  border: none;
  text-align: center;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  font-family: @main-font;
  letter-spacing: 1px;
  cursor: pointer;
  line-height: 150px;
  padding: 0 10px;
  display: block;

  input[type="radio"]{
    position: absolute;
    opacity: 0;
    left: -999px;
    top: -999px;
  }

  .button_color(@blue);

  i{
    display: block;
    font-size: 80px;
    margin-bottom: 5px;
    line-height: initial;
    padding-top: 20px;
  }

  span{
    color: #fff;
  }

  img{
    display: block;
    margin: 0 auto;
    padding: 20px 20px 10px 20px;
    max-height: 100px;
    max-width: 100%;
  }

  i + span, img + span{
    line-height: initial;
    display: block;
  }
}

input[type="radio"]:checked + label.model{
  background: darken(@blue,20);
}


.model-img{
  border: 1px solid lightgrey;
  padding: 15px;
  width: 100%;
  margin-bottom: 10px;
  img{
    height: auto;
    max-height: 110px;
    max-width: 100%;
    display: block;
    margin: 0 auto;
    padding: 20px;
    background: @blue;
  }

}