.notice{
  padding: 5px 15px;
  margin-bottom: 0;
  font-size: 12px;
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.mce-floatpanel {
  z-index: 99999 !important;
}