button.button-circle{
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  border: none;
  .button_color(@blue);


  &--grey{
    .button_color(@dark-grey);
  }

}

.btn.btn-mini{
  height: 25px;
  width: 25px;
  padding: 0;
  font-size: 11px;
  color: @dark-grey;
  background: #fff;
  &.active{
    background: @grey;
  }
}


@media(max-width: @screen-desktop) {
  .btn-group .btn {
    padding: 5px;
    font-size: 11px;
  }
}

.alert .btn.level {
  border-radius: 50px;
  background-color: #fff;
  color: #000;
}