.card-statistics{
  min-height: 250px;
  position: relative;
  margin: 100px 0;
}

.header-link{
  font-size: 16px;
  float: right;
}

.datepicker--container{
  display: inline-block;
  position: relative;
  .select--arrow{
    position: absolute;
    top: 0px;
    right: 10px;
    pointer-events: none;
  }
}

.datetimepicker{
  font-size: 18px;
  margin: -5px 5px 0 5px;
  border: 1px solid grey;
  padding: 5px 25px 5px 5px;
  width: 200px;

  &:after{
    content: "";
  }
}

.stat-box {
  color: #fff;
  padding: 10px 15px 35px 15px;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 1px;

  i {
    position: absolute;
    font-size: 74px;
    right: 20px;
    opacity: 0.25;
  }

  strong {
    display: block;
    margin-top: 10px;
    font-weight: normal;
  }

  &.blue {
    background-color: @blue;
  }
  &.yellow {
    background-color: @yellow;
  }

  &.red {
    background-color: @red;
  }
}