.modal--background{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.17);
  z-index: 9999;

  .modal--container{
    margin: 40px auto;
    width: 400px;
    background: #fff;
    box-shadow: 0 2px 10px 2px rgba(0,0,0,0.3);
    border-radius: 10px;
  }

  .modal--content {
    padding: 15px;
  }

  .close {
    margin-top: 5px;
    margin-right: 5px;
  }

}



.modal--container.big {
  width: 800px;
  max-width: 90%;
}


.modal--header{
  width: 100%;
  padding: 7px 15px;

  h2 {
    margin: 0;
  }
}


.modal--footer {
  padding: 7px 15px;
  border-top: 1px solid #cdcdcd;
  text-align: right;
}


.resizer {
  width: 800px;
  height: 700px;
  overflow: hidden;

  .cr-boundary {
    width:600px !important;
    height:600px !important;
  }

}