.smartpage-bandeau{
  background: @grey;
  height: 80px;

  .cancel{
    line-height: 80px;
    padding: 0 15px;

    span{
      font-family: @main-font;
      font-size: 14px;
    }

    button[disabled]{
      background: lighten(@dark-grey,20);
      cursor: not-allowed;
    }
  }

  .section{
    height: 80px;
    border-right: 1px solid #fff;
  }

  .btn-group{
    padding-top: 18px;
    label,a{
      font-family: @main-font;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  .btn-save{
    margin: 20px 15px;
  }


}

body {
  .smartpage-bandeau-bleu {
    background-color: @dark-blue;
    color: #fff;
    h2 {
      color: #fff;
      text-transform: uppercase;
      text-align: right;
    }

    input[type='text'] {
      width: 50%;
      margin-left: 10px;
      margin-right: 10px;
    }

    .error-editor {
      text-align: left;
    }

    a {
      display: block;
      text-align: left;
      padding-left: 15px;
      margin-top: 20px;
      color: #fff;
      font-size: 24px;
      text-transform: uppercase;
      font-family: 'yanone_kaffeesatzregular', Arial, sans-serif;
      i {
        margin-top: -6px;
        margin-right: 10px;
        font-size: 30px;
        color: #c7c7c7;
      }
    }

  }
}